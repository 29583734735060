import { Navbar } from "./components/navbar";
import "./App.css";
import HeroBanner from "./components/heroBanner";
const heroBanner = require("./components/hero.png");

const header = "Leksaker som är redo för NYA äventyr";

function App() {
  return (
    <div className="h-full w-screen bg-primary text-center">
      <Navbar />
      <HeroBanner
        image={heroBanner}
        header={header}
        subheader="Här kan du hitta leksaker som är redo att skapa nya minnen"
      />
    </div>
  );
}

export default App;
