import { FaQuestion } from "react-icons/fa";
const logo = require("../../logo1.png");

const Navbar = () => {
  return (
    <>
      <nav className="h-[100px] w-screen bg-white flex items-center px-5 justify-between bg-opacity-75 absolute shadow-lg">
        <div className="logo flex-shrink-0 mr-40">
          <img src={logo} alt="Logo" className="h-[150px] w-full mt-1" />
        </div>
        <div className="search-field flex-grow mx-5 hidden lg:block">
          <input
            type="text"
            placeholder="Vad letar du efter?"
            className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none w-full max-w-[500px]"
          />
        </div>
        <ul className="flex items-center space-x-4 flex-shrink-0 ml-64 hidden lg:block">
          <li>
            <a href="/about" className="hover:text-gray-400">
              <FaQuestion />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
