type HeroBannerProps = {
  image: string;
  children?: React.ReactNode;
  header?: string;
  subheader?: string;
};

const HeroBanner = ({
  image,
  children,
  header,
  subheader,
}: HeroBannerProps) => {
  const backgroundImageStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div className="flex justify-center w-screen bg-white">
      <div
        className="flex justify-left md:pt-[2.5rem] h-[45vh] w-3/4 mt-[7rem]"
        style={backgroundImageStyle}
      >
        <div className="h-12 w-2/4">
          <h1 className=" text-white font-bold drop-shadow-2xl lg:text-7xl md:text-5xl text-4xl ">
            {header}
          </h1>
          <h4 className="lg:text-2xl md:text-2xl text-xl text-white drop-shadow-2xl">
            {subheader}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;

/*

          <div className="w-full">
            <button className="h-12 min-w-24 bg-secondary text-white rounded-full mt-24 px-6 ml-6">
              <a href="#about" className="text-white text-base lg:text-xl">
                Hitta nya leksaker
              </a>
            </button>
            <div className="flex flex-col items-center">
              <button className="h-12 min-w-24 bg-secondary text-white rounded-full mt-6 px-6 ml-6">
                <a href="#about" className="text-white text-base lg:text-xl">
                  Sälj dina leksaker
                </a>
              </button>
              <div className="bg-white w-44 bg-opacity-50 rounded-xl fade-in-from-above mt-2 px-6 ml-6">
                <p>Vi kommer och hämtar dom hos dig</p>
              </div>
            </div>
          </div>
*/
